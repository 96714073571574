import { BuildName, Environment } from './environment.model';

const environment: Environment = {
  appVersion: '@APP_VERSION',
  production: false,
  name: BuildName.cmpdev,
  versionName: 'dev',
  homeRouterLink: '/pages/azure-usage/',
  appInsight: '3db105ea-74eb-4d42-bd79-fc8399ba4020',
  buildNumber: '20241022.1',
  apiServer: {
    url: 'https://csp-api-dev.ai168.cloud',
    // url: 'https://csp-api.ai168.cloud',
    // url: 'https://localhost:44364',
    ssoConfig: 'spaSSOConfig',
    minutesToRefresh: 2
  },
  cmpAPIServer: {
    url: 'https://cmp-api-dev.ai168.cloud'
    // url: 'https://cmp-api.ai168.cloud'
    // url: 'https://localhost:44310'
  },
  gcpAPIServer: {
    url: 'https://gcp-api-dev.ai168.cloud'
    // url: 'https://gcp-api.ai168.cloud'
    // url: 'https://localhost:44347'
  },
  awsAPIServer: {
    url: 'https://aws-api-dev.ai168.cloud'
    // url: 'https://aws-api.ai168.cloud'
    // url: 'https://localhost:44391'
  },
  xcloudAPIServer: {
    url: 'https://xcloud-api-dev.ai168.cloud'
    // url: 'https://xcloud-api.ai168.cloud'
    // url: 'https://localhost:44312'
  },
  m365APIServer: {
    url: 'https://m365-api-dev.ai168.cloud'
    // url: 'https://m365-api.ai168.cloud'
    // url: 'https://localhost:44348'
  }
};

export default environment;
